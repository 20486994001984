/* eslint-disable max-len */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import { Button } from 'components/forms/Button';
import {
	Form as FormPhone, Input as InputPhone, Label as LabelPhone, Prefix as PrefixPhone,
} from 'components/forms/PhoneInput/styledComponent';

import {
	Form as FormTextArea,
	Input as InputTextArea,
	Label as LabelTextArea,
} from 'components/forms/TextAreaInput/styledComponent';
import { Form as FormText, Input as InputText, Label as LabelText } from 'components/forms/TextInput/styledComponent';
import FooterBar from 'components/layout/FooterBar';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import actions from 'store/actions';
import { INotification } from 'store/interfaces/App.Interface';
import selectors from 'store/selectors';
import {
	Main,
	BodyPage,
	CallMeBlock,
	CallMeBlockContent,
	CallMeBlockItem,
	CallMeBlockTitle,
	CallMeBlockServ,
	CallMeBlockContentServ,
	CallMeBlockItemServ,
	CallMeBlockTitleServ,
	Content,
	DescriptionContent,
	FeedBackBlock,
	FeedBackSubTitle,
	FeedBackSubTitleDescription,
	FeedBackSubTitleEmail,
	FeedBackTitle,
	TitleMain,
	NotificationModal,
	FormContacts,
	// NotificationModal,
} from './styledComponent';

type FormData = {
	phone: string;
	name: string;
	message: string;
	email:string;
  };

const ContactsPage: React.FC = () => {
	const {
		register, handleSubmit, formState: { errors }, reset,
	} = useForm<FormData>();
	const dispatch = useDispatch();
	const currentNotifications : INotification[] = useSelector(selectors.app.getNotification);
	const currentLoading : string[] = useSelector(selectors.app.getCurrentLoading);
	const successfulApi : string[] = useSelector(selectors.app.getSuccessfulApi);
	const { width } = useWindowDimensions();

	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};
	const onSubmit = handleSubmit((data) => {
		dispatch(actions.app.saga.asyncSendSupport({
			phone: data.phone, name: data.name, message: data.message, email: data.email,
		}));
	});

	useEffect(() => {
		if (successfulApi.find((item) => item === 'SendSupportMessageRequest')) {
			reset();
			dispatch(actions.app.setSuccessfulApi(successfulApi?.filter((value) => value !== 'SendSupportMessageRequest')));
		}
	}, [successfulApi]);

	return (
		<Main>
			{ Array.isArray(currentNotifications) ? currentNotifications?.map((item) => (
				<NotificationModal>
					<span>{item.messages}</span>
				</NotificationModal>
			)) : null}
			{getDeviceType() === 'laptop' && (
				<TitleMain>
					<span>Контакты</span>
				</TitleMain>
			)}
			<BodyPage>
				{getDeviceType() === 'mobile' && (
					<TitleMain>
						<span>Контакты</span>
					</TitleMain>
				)}
				<Content>
					<DescriptionContent>
						<span>
							Оператором муниципальных парковок является МКУ г. Красноярска «Управление дорог, инфраструктуры и благоустройства»
							- МКУ «УДИБ»
						</span>
					</DescriptionContent>
					<CallMeBlock>
						<CallMeBlockTitle>
							<span>Позвонить нам</span>
						</CallMeBlockTitle>
						<CallMeBlockContent>
							<CallMeBlockItem>
								<span>Рабочий офис:</span>
								<span>В будни с 8 до 17, обед с 12 до 13</span>
								<span>
									8 (391)
									{' '}
									<span>206 14 14</span>
								</span>
							</CallMeBlockItem>
							<CallMeBlockItem>
								<span>Диспетчерская служба</span>
								<span>Круглосуточно</span>
								<span>
									8 (391)
									{' '}
									<span>206 14 14</span>
								</span>
							</CallMeBlockItem>
						</CallMeBlockContent>
					</CallMeBlock>
					<CallMeBlockServ>
						<CallMeBlockTitleServ>
							<span>Обслуживающая организация</span>
						</CallMeBlockTitleServ>
						<CallMeBlockContentServ>
							<CallMeBlockItemServ>
								<span>ООО «Турастройсервис»</span>
								<span>ИНН: 2470000242/ КПП: 247001001</span>
								<span>ОГРН: 1072470000921</span>
								<span>
									8 (908)
									{' '}
									<span>221 62 13</span>
								</span>
								<a href="mailto:s163669@yandex.ru?subject=Вопрос по КрасПаркинг">
									s163669@yandex.ru
								</a>

							</CallMeBlockItemServ>
						</CallMeBlockContentServ>
					</CallMeBlockServ>
					<FeedBackBlock>
						<FeedBackTitle>
							<span>Написать нам</span>
						</FeedBackTitle>
						<FeedBackSubTitle>
							<FeedBackSubTitleDescription>
								<span>
									Вы можете оставить любые предложения, пожелания и мнения о работе «КрасПаркинга»,
									написав на электронную почту или в сообщении ниже, указав свой контактный телефон:
								</span>
							</FeedBackSubTitleDescription>
							<FeedBackSubTitleEmail>
								<a href="mailto:krasparking@admkrsk.ru?subject=Вопрос по КрасПаркинг">
									krasparking@admkrsk.ru
								</a>
							</FeedBackSubTitleEmail>
						</FeedBackSubTitle>
						<FormContacts onSubmit={onSubmit}>
							<div
								className="phone-contacts"
							>
								{/* <PhoneInput
									onChange={onChangePhone}
									label="Номер телефона"
									name="phone"
									placeholder="Ваш номер телефона"
									value={phoneInput}
								/> */}
								<FormPhone>
									<LabelPhone htmlFor="input-field">Номер телефона</LabelPhone>
									<PrefixPhone>
										<InputPhone
											placeholder="Ваш номер телефона"
											autoComplete="off"
											{...register('phone', {
												required: true,
												maxLength: 10,
												pattern: /\(?([0-9]{3})\)?([-]?)([0-9]{3})\2([0-9]{4})/,
											})}
										/>

									</PrefixPhone>
									{errors.phone && <p>Некорректный номер телефона</p>}

								</FormPhone>

							</div>
							<div
								className="email-contacts"
							>
								{/* <TextInput
									onChange={onChangeName}
									label="Ваше ФИО"
									name="name"
									placeholder="Полное имя"
									value={nameInput}
								/> */}
								<FormText>
									<LabelText>E-mail</LabelText>
									<InputText
										type="text"
										autoComplete="off"
										placeholder="Ваш email"
										{...register('email', {
											required: true,
											// eslint-disable-next-line max-len
											pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/,
										})}
									/>
									{errors.email && <p>Некорректный email</p>}
								</FormText>

							</div>
							<div
								className="name-contacts"
							>
								{/* <TextInput
									onChange={onChangeName}
									label="Ваше ФИО"
									name="name"
									placeholder="Полное имя"
									value={nameInput}
								/> */}
								<FormText>
									<LabelText htmlFor="input-field">Ваше ФИО</LabelText>
									<InputText
										type="text"
										autoComplete="off"
										placeholder="Полное имя"
										{...register('name', {
											required: true,
											maxLength: 50,
											minLength: 3,
										})}
									/>
									{errors.name && <p>Некорректное имя</p>}
								</FormText>

							</div>

							<FormTextArea
								className="area-contacts"
							>
								<LabelTextArea htmlFor="input-field">Что вы хотите сообщить?</LabelTextArea>
								<InputTextArea
									autoComplete="off"
									{...register('message', {
										required: true,
										maxLength: 999,
										minLength: 3,
									})}
								/>
								{errors.message && <p>Некорректное сообщение</p>}
							</FormTextArea>

							<div className="button-contacts">
								<Button
									disabled={!currentLoading.findIndex((item) => item === 'buttonSendMessages')}
									type="submit"
								>
									Отправить

								</Button>
							</div>
						</FormContacts>
					</FeedBackBlock>
				</Content>
			</BodyPage>
			<FooterBar />
		</Main>
	);
};

export default ContactsPage;

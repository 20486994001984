/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import { TDevice } from 'common/devices';
import useWindowDimensions from 'common/useWindowDimensions';
import HeaderBar from 'components/layout/HeaderBar';
import HeaderBarCabinet from 'components/layout/HeaderBarCabinet';
import AboutPage from 'components/Pages/About';
import AdminLoginPage from 'components/Pages/Admin/Login';
import AdminPage from 'components/Pages/Admin/MainPage';
import ContactsPage from 'components/Pages/Contacts';
import { NotificationModal } from 'components/Pages/Contacts/styledComponent';
import DocumentPage from 'components/Pages/DocumentPage';
// import HistoryPage from 'components/Pages/History';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import HomePage from 'components/Pages/Home';
import LoginPage from 'components/Pages/Login';
import MapPage from 'components/Pages/Map';
import MapMobilePage from 'components/Pages/MapMobile';
import ProfilePage from 'components/Pages/Profile';
import ProfileMobilePage from 'components/Pages/ProfileMobile';
import RegistrationPage from 'components/Pages/Registration';
import ResetPasswordPage from 'components/Pages/ResetPasswordPage';
import VerificationPhonePage from 'components/Pages/VerificationPhone';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Route, useLocation, Routes, Navigate,
} from 'react-router-dom';

import tokenService from 'service/token.service';
import { INotification } from 'store/interfaces/App.Interface';
import selectors from 'store/selectors';
import { ruRU } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';
import {
	CloseOutlined,
} from '@ant-design/icons';
import actions from 'store/actions';
// import SearchPage from 'components/Pages/SearchPage';
import SearchNewPage from 'components/Pages/SearchNewPage';
import { GlobalStyles } from './common/GlobalStyle';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
}, ruRU);

const App: React.FC = () => {
	const { pathname } = useLocation();
	const { width } = useWindowDimensions();
	const dispatch = useDispatch();

	const getDeviceType = (): TDevice => {
		if (width <= 768) return 'mobile';
		return 'laptop';
	};
	const currentNotifications : INotification[] = useSelector(selectors.app.getNotification);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
		switch (pathname) {
		case '/':
			document.title = 'КрасПаркинг';
			break;
		default:
			document.title = 'КрасПаркинг';
			break;
		}
	}, [pathname]);

	return (
		<div>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<ThemeProvider theme={darkTheme}>
					<ConfigProvider
						locale={ruRu}
						theme={{
							token: {
								colorPrimary: '#ff8a00',
								colorBgContainer: '#ffffff00',
								colorText: '#fff',
								colorTextSecondary: '#fff',
								colorTextPlaceholder: '#818181',
								colorBgElevated: '#2e2e2e',
								colorBgLayout: 'rgb(10, 10, 21)',
								controlItemBgActive: '#818181',
								colorIcon: 'rgb(255, 255, 255)',
								colorFillContent: 'rgb(255, 255, 255)',
								colorFill: 'rgb(255, 255, 255)',
								colorFillContentHover: 'rgb(255, 255, 255)',
								colorFillAlter: 'rgb(255, 255, 255)',
								// colorTextDisabled: '#fff',
								// color: '#fff',
							},
							components: {
								Select: {
									colorBgLayout: 'red',
									colorText: '#fff',
								},
								Modal: {
									colorBgElevated: '#1d2335',
								},
								Button: {
									colorTextDisabled: 'gray',
									controlItemBgActiveDisabled: 'gray',
								},
								Input: {
									colorText: '#fff',
									colorTextDisabled: '#fff',
									colorIcon: '#fff',
								},
								DatePicker: {
									colorText: '#fff',
									colorTextDisabled: '#8b8b8b',
									colorIcon: '#fff',
								},
							},
						}}
					>
						{currentNotifications?.map((item) => (
							<NotificationModal key={item?.id}>
								<div
									className="close-icon-notification-modal"
									onClick={() => dispatch(actions.app.saga.asyncRemoveNotification(item?.id))}
								>
									<CloseOutlined />
								</div>
								<span>{item?.messages}</span>
							</NotificationModal>
						))}
						<GlobalStyles />
						{
							pathname.split('/')[1] === 'map'
			|| pathname.split('/')[1] === 'profile'
			|| pathname.split('/')[1] === 'history'
			|| (pathname.split('/')[1] === 'reset-password' && tokenService.isLoggedIn)
								? (getDeviceType() === 'laptop' && <HeaderBarCabinet />) : (pathname.split('/')[1] !== 'admin' && <HeaderBar />)
						}
						<Routes>
							<Route path="*" element={<Navigate to="" />} />
							<Route path="" element={<HomePage />} />
							<Route path="admin">
								<Route path=":id" element={tokenService.isLoggedIn ? <AdminPage /> : <Navigate to="/login-admin" />} />
							</Route>
							<Route path="login" element={<LoginPage />} />
							<Route path="login-admin" element={<AdminLoginPage />} />
							<Route path="registration" element={<RegistrationPage />} />
							<Route
								path="profile"
								element={tokenService.isLoggedIn
									? (getDeviceType() === 'laptop' ? <ProfilePage /> : <ProfileMobilePage />) : <Navigate to="/login" />}
							/>
							<Route
								path="search-active"
								element={tokenService.isLoggedIn
									? (getDeviceType() === 'mobile' ? <SearchNewPage /> : <Navigate to="/map/search" />) : <Navigate to="/login" />}
							/>
							<Route path="about" element={<AboutPage />} />
							<Route path="contacts" element={<ContactsPage />} />
							<Route path="verification" element={<VerificationPhonePage />} />
							<Route path="about/document" element={<DocumentPage />} />
							<Route path="map">
								<Route
									path=":id"
									element={tokenService.isLoggedIn
										? (getDeviceType() === 'laptop' ? <MapPage /> : <MapMobilePage />)
										: <Navigate to="/login" />}
								/>
							</Route>
							<Route path="reset-password">
								<Route path=":id" element={<ResetPasswordPage />} />
							</Route>
						</Routes>
					</ConfigProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</div>

	);
};

export default App;

import styled from 'styled-components';
import HeaderSVG from 'assets/icons/HomePage/ParkomatBox.svg';
import Step1SVG from 'assets/icons/HomePage/PrincipleStep1.svg';
import Step2SVG from 'assets/icons/HomePage/PrincipleStep2.svg';
import LeftBottomSVG from 'assets/icons/HomePage/BottomLeft.svg';
import BottomPastomatSVG from 'assets/icons/HomePage/BottomPostomat.svg';
import RightBottomSVG from 'assets/icons/HomePage/BottomRight.svg';
import TopIconSVG from 'assets/icons/HomePage/top_Icon.svg';
import CarZamokSVG from 'assets/icons/HomePage/car_zamok.svg';
import newZamokSVG from 'assets/icons/HomePage/newZamok.svg';
import { device } from 'common/devices';

export const ParkomatIcon = styled(HeaderSVG)`
  right: 0;
  top: 0;
  /* position: absolute; */
  width: 100%;
  /* height: 100%; */
`;
export const TopIconMobile = styled(TopIconSVG)`
  right: 0;
  top: 0;
  position: relative;
  width: 100%;
  /* height: 100%; */
`;

export const NewZamokIcon = styled(newZamokSVG)`
  z-index: 0;
  position: relative;

  bottom: 0;
  left: 0;
  width: 100%;
  @media ${device.tablet600} {
    display: none;
  }
`;

export const CarZamokIcon = styled(CarZamokSVG)`
  display: none;
  position: relative;
  @media ${device.tablet600} {
    display: block;
    top: 0%;
    width: 100%;
  }
`;

export const Step1Icon = styled(Step1SVG)``;
export const Step2Icon = styled(Step2SVG)``;
export const LeftBottomIcon = styled(LeftBottomSVG)`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  @media ${device.tablet600} {
    display: none;
  }
`;
export const BottomPastomatIcon = styled(BottomPastomatSVG)`
  position: absolute;
  width: 40%;
  right: 0;
  /* top: 0; */
  /* z-index: 0; */
  @media ${device.tablet600} {
    display: none;
  }
`;
export const RightBottomIcon = styled(RightBottomSVG)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  @media ${device.tablet600} {
    display: none;
  }
`;

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
  /* or 55px */
  letter-spacing: 0.1px;
  color: #ffffff;
  @media ${device.tablet600} {
    font-weight: 700;
    font-size: 32px;
  }
`;
export const SubTitleSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1px;

  color: #ffffff;
`;
export const MainSection = styled.div`

  @media ${device.tablet600} {
    --paddingMain: 24px;
  }
`;
// First section
export const SectionTitle = styled.div`
  position: relative;
  width: 100%;
  /* height: 100vh; */
  @media ${device.tablet600} {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
  }
`;
export const TitleText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 10%;
  top: 30%;
  z-index: 1;
  /* gap: 30px; */
  @media ${device.tablet600} {
    margin: -30px var(--paddingMain) 100px var(--paddingMain);
    top: 0%;
    left: 0%;
    gap: 24px;
    position: relative;
  }
`;
export const TitleMainSpan = styled(TitleSpan)`
  margin-bottom: 24px;
`;
export const TitleSubSpan = styled(SubTitleSpan)`
  margin-bottom: 40px;
`;

// Principle section

export const SectionPrinciple = styled.div`
  position: relative;
  width: 100%;
  /* height: 100vh; */
  box-sizing: border-box;
  /* padding-right: 120px; */
  /* display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) 1.2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px; */

  @media ${device.tablet600} {
    height: auto;
    padding-right: 0px;
    /* padding-right: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 1.1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    display: flex;
    flex-direction: column;
    margin-bottom: 170px;
  }
`;
export const IconPrinciple = styled.div`
    display: none;
  grid-area: 1 / 1 / 4 / 2;
  @media ${device.tablet600} {
    /* position: relative; */
    /* grid-area: 1 / 1 / 2 / 2; */
    /* margin-top:5vh; */
    display: block;
    width: 100%;
  }
`;
export const TitlePrinciple = styled.div`
position:absolute;
  z-index: 1;
  width: 45%;
  left:50%;
  top:5%;
  /* grid-area: 1 / 2 / 4 / 4; */
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 4.5vw;
  flex-direction: column-gap;
  @media ${device.tablet600} {
    position: initial;
    margin-top: -10vh;
    padding: 0px var(--paddingMain);
    box-sizing: border-box;
    width: 100%;
    grid-area: 2 / 1 / 3 / 2;
  }
`;
export const PrincipleMainSpan = styled(TitleSpan)`
  line-height: 110%;
`;
export const PrincipleSubSpan = styled(SubTitleSpan)`
  @media ${device.tablet600} {
    font-size: 16px;
    line-height: 160%;
  }
`;

export const PrincipleSubElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
  @media ${device.tablet600} {
    gap: 30px;
    margin-top: 40px;
  }
`;
export const PrincipleSubSpanPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  @media ${device.tablet600} {
    align-items: flex-start;
  }
`;
export const PrincipleSubSpanPointStage = styled.div`
  /* display: inline-block; */
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: #ff8a00;
    border-radius: 50%;
    @media ${device.tablet600} {
      height: 32px;
      width: 32px;
    }
    @media ${device.tablet600} {
      margin-top: -6px;
    }
  }
`;
export const PrincipleSubSpanPointStageName = styled(SubTitleSpan)`
  /* display: inline-block; */
`;
export const PrincipleSubSpanPointName = styled(SubTitleSpan)``;
export const SubTitlePrinciple = styled.div`
  display: flex;
  flex-direction: row;
  grid-area: 3 / 2 / 4 / 4;
  justify-self: center;
  align-self: start;
  width: 100%;
  justify-content: space-around;
  div {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    svg {
      align-self: end;
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    letter-spacing: 0.1px;

    color: #ffffff;
  }
  @media ${device.tablet600} {
    span {
      font-size: 16px;
    }
  }
`;
// Map section
export const SectionMap = styled.div`
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  gap: 50px;
  .leaflet-container {
    width: 100%;
    height: 80vh;
    .leaflet-control-attribution {
      display: none;
    }
  }

  @media ${device.tablet600} {
    gap: 40px;
  }
`;
export const MapMainSpan = styled(TitleSpan)`
  align-self: center;
  @media ${device.tablet600} {
    padding: 0px var(--paddingMain);
    box-sizing: border-box;
  }
`;
export const MapElement = styled.div`
  position: relative;
  .marker-popup .leaflet-popup-tip {
    background: #171725;
  }
  .leaflet-container .leaflet-popup-close-button{
      display:none;
    }

  .marker-popup .leaflet-popup-content-wrapper {
    background: #171725;
    border-radius: 8px;
    display: flex;
    span:nth-child(1) {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.1px;

      color: #ffffff;
    }
    span:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.1px;

      /* App/Gray */

      color: #92929d;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .leaflet-popup-content {
      margin: 8px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }
`;

export const BlurEffect = styled.div`
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 5000;
  &.show {
    display: flex;
  }
  &.out {
    display: none;
  }
`;

export const BlurElement = styled.div`
  z-index: 1001;
  position: absolute;
  pointer-events: none;
  top: -2px;
  width: 100%;
  height: 30%;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 0) 0%,
    rgba(10, 10, 21, 1) 99%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .sub-title-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5%;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span:nth-child(1) {
        font-style: normal;
        font-weight: 500;
        font-size: 60px;
        line-height: 82px;
        /* identical to box height */

        letter-spacing: 0.1px;

        /* Orange */

        color: #ff8a00;
      }
      span:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height */

        letter-spacing: 0.1px;

        color: #ffffff;
      }
    }
  }
  @media ${device.tablet600} {
    top: -10px;
    .sub-title-search {
      gap: 10%;
    }
  }
`;

// Price section
export const SectionBottom = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  @media ${device.tablet600} {
    padding: 0px var(--paddingMain);
    box-sizing: border-box;
  }
`;
export const SectionPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 1;
  margin-bottom: 69px;
  @media ${device.tablet600} {
  }
`;
export const PriceTitle = styled(TitleSpan)`
  margin-top: 120px;

  @media ${device.tablet600} {
    margin-top: 69px;
    text-align: center;
  }
`;
export const TariffBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Tariff = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 380px;
  height: 100%;
  background: #283046;
  border-radius: 30px;
  padding: 40px;
  align-items: flex-start;
  justify-content: space-around;
  gap: 24px;
  span:nth-child(1) {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */

    letter-spacing: 0.1px;

    color: #ff8a00;
  }
  span:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;

    color: #ffffff;
  }

  div {
    display: flex;
    flex-direction: column;
    span:nth-child(1) {
      font-weight: 700;
      font-size: 19px;
      line-height: 26px;
      /* identical to box height */

      letter-spacing: 0.1px;

      color: #727a8e;
    }
    span:nth-child(2) {
      font-weight: 500;
      font-size: 50px;
      line-height: 68px;
      letter-spacing: 0.1px;

      color: #ffffff;
    }
  }
  @media ${device.tablet600} {
    width: 100%;
    height: 100%;
  }
`;

export const SectionFAQ = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 69px;
  @media ${device.tablet600} {
    gap: 40px;
  }
`;

export const TitleFAQ = styled(TitleSpan)``;

export const FAQLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
  @media ${device.tablet600} {
    width: 100%;
  }
`;

import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
import { unstable_HistoryRouter as BrowserRouter } from 'react-router-dom';
import './style.css';
import { Provider } from 'react-redux';
import rootStore from 'store/store';
import { history } from 'common/history';
import App from './app';

ReactDOM.render(
	<Provider store={rootStore}>
		<BrowserRouter history={history}>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root'),
);
